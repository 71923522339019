import React from 'react';

import { styled } from '@mui/material';

export type MewaStepsProps = {
  steps: {
    icon: React.ReactElement;
    title: string;
    description: string;
  }[];
};

export const MewaSteps = ({ steps: items }: MewaStepsProps) => {
  return (
    <Container>
      {items
        .map((item, index) => (
          <Item key={index} {...{ ...item, number: index + 1 }} />
        ))
        .map((item, index) =>
          index !== items.length - 1 ? (
            <React.Fragment key={index}>
              {item}
              <Arrow></Arrow>
            </React.Fragment>
          ) : (
            item
          ),
        )}
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(5, auto)',
  width: '100%',
  margin: '0 auto',

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr auto) 1fr',
  },

  [theme.breakpoints.up('md')]: {
    width: '83.33334%',
  },
}));

const Arrow = styled('div')(({ theme }) => ({
  background:
    "url(\"data:image/svg+xml,%3Csvg width='66' height='25' viewBox='0 0 66 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32.9995 25L65.3047 5.31556V0L32.9995 19.6844L0.694298 0V5.31556L32.9995 25Z' fill='%23D3D3D3'/%3E%3C/svg%3E%0A\") center bottom no-repeat",
  height: '65px',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 26 66' fill='none'%3E%3Cpath d='M25.5 32.9995L5.81556 0.694336H0.5L20.1844 32.9995L0.5 65.3047H5.81556L25.5 32.9995Z' fill='%23D3D3D3'/%3E%3C/svg%3E\") center center no-repeat",
    height: '65px',
    width: '90px',
    rotate: 'unset',
    margin: 'auto 0',
  },

  [theme.breakpoints.up('md')]: {
    height: '86px',
  },
}));

const Item = ({
  icon,
  number,
  title,
  description,
}: {
  icon: React.ReactElement;
  number: number;
  title: string;
  description: string;
}) => {
  return (
    <div>
      <IconContainer>{icon}</IconContainer>
      <Title>
        <Number>{number.toFixed(0).padStart(2, '0')}.</Number>
        {title}
      </Title>
      <Description>{description}</Description>
    </div>
  );
};

const IconContainer = styled('div')(({ theme }) => ({
  svg: {
    height: '70px',
    width: '70px',

    [theme.breakpoints.up('md')]: {
      height: '90px',
      width: '90px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '100px',
      width: '100px',
    },
  },
}));

const Title = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  margin: '10px 0 10px',
}));

const Number = styled('span')(({ theme }) => ({
  fontSize: '27px',
  margin: '0 10px 0 0',

  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: '42px',
  },
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  margin: 0,
}));
