import { SiteSearch } from '@piwikpro/react-piwik-pro';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PageType } from '@mewa/types';

import { decodeHtmlChars } from '../../templates/search/helper';
import {
  PageFindInstance,
  PageFindSearchResult,
  SearchResult,
  SearchResultGroups,
  SearchResultMeta,
  SearchSections,
} from './types';

export const emptySearchResultGroups: SearchResultGroups = {
  productDetail: [],
  serviceSegment: [],
  pressRelease: [],
  pressOverview: [],
  blogArticle: [],
  blogAuthor: [],
  blogOverview: [],
  blogTopic: [],
  collection: [],
  category: [],
  industry: [],
  company: [],
  mewaPrinciple: [],
  campaign: [],
  conversion: [],
  cleaningClothsServiceTest: [],
  home: [],
  legal: [],
  meta: [],
  search: [],
  supplyChainAct: [],
  fullService: [],
};

const decodeAllMetaData = (result: SearchResult): SearchResult => ({
  ...result,
  meta: Object.fromEntries(
    Object.entries(result.meta).map(([key, value]) => [
      key,
      decodeHtmlChars(value),
    ]),
  ) as SearchResultMeta,
});

export const useSearchResult = (initialSearchTerm?: string) => {
  const [results, setResults] = useState<SearchResultGroups>(
    emptySearchResultGroups,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState(!!initialSearchTerm);
  const pfInstance = useRef<PageFindInstance | null>(null);
  const trackingIntentData = useRef<{
    searchTerm: string;
    category: string | undefined;
    count?: number;
  } | null>(null);

  const trackSiteSearch = useCallback(
    (searchTerm: string, category: string | undefined, count?: number) => {
      if (isLoading) {
        trackingIntentData.current = {
          searchTerm,
          category,
          count,
        };
      } else {
        SiteSearch.trackSiteSearch(searchTerm, category, count);
      }
    },
    [isLoading],
  );

  const getSearchResultData = useCallback(
    async (searchResult: PageFindSearchResult, searchTerm: string) => {
      if (searchResult) {
        const results = await Promise.all(
          // resolve promise of `data` and spread its properties into the search result entry
          searchResult.results.map(
            async ({ data, ...searchResultProperties }) => ({
              ...searchResultProperties,
              ...(await data()),
            }),
          ),
        );

        const groupedResults = groupSearchResults(
          results.map(decodeAllMetaData),
        );

        setResults(groupedResults);
      }
    },
    [],
  );

  const triggerSearch = useCallback(
    (searchTerm: string) => {
      if (searchTerm.trim() !== '') {
        setIsLoading(true);

        // we have to increase the duration to ensure a rerender for the isLoading state change
        setTimeout(async () => {
          setSearchTerm(searchTerm);
          await pfInstance.current
            ?.search(searchTerm)
            .then((results) => getSearchResultData(results, searchTerm));
          setIsLoading(false);
        }, 10);
      } else {
        setResults(emptySearchResultGroups);
        setIsLoading(false);
      }
    },
    [pfInstance, getSearchResultData],
  );

  const initializePageFind = useCallback(async () => {
    if (pfInstance.current === null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @nx/enforce-module-boundaries
      await import(/*webpackIgnore: true*/ '/pagefind/pagefind.js').then(
        async (pagefind: PageFindInstance) => {
          await pagefind.init();
          pfInstance.current = pagefind;
        },
      );
    }

    if (initialSearchTerm !== undefined) {
      triggerSearch(initialSearchTerm);
    }
  }, [initialSearchTerm, triggerSearch]);

  const clearSearch = useCallback(() => {
    setResults(emptySearchResultGroups);
    setSearchTerm('');
  }, []);

  useEffect(() => {
    initializePageFind();
  }, [initializePageFind]);

  useEffect(() => {
    if (!isLoading && trackingIntentData.current !== null) {
      const { searchTerm, category, count } = trackingIntentData.current;
      trackSiteSearch(searchTerm, category, count);
      trackingIntentData.current = null;
    }
  }, [isLoading, trackSiteSearch]);

  return {
    triggerSearch,
    clearSearch,
    results: getSearchSections(results),
    searchTerm,
    pfInstance,
    isLoading,
    totalCount: getTotalCount(results),
    trackSiteSearch,
  };
};

export const getSearchSections = (
  results: SearchResultGroups,
): SearchSections => {
  return {
    productDetail: results.productDetail,
    pressRelease: results.pressRelease,
    brandTopic: [
      ...results.mewaPrinciple,
      ...results.company,
      ...results.campaign,
    ],
    category: [
      ...results.serviceSegment,
      ...results.collection,
      ...results.industry,
    ],
  };
};

export const groupSearchResults = (results: SearchResult[]) =>
  results.reduce<SearchResultGroups>((pageTypes, result) => {
    const pageType = Object.values(PageType).find(
      (p) => p === result.meta.page_type,
    );

    if (pageType) {
      pageTypes[pageType].push(result);
    }

    return pageTypes;
  }, getEmptyPageTypeMap());

const getEmptyPageTypeMap = (): SearchResultGroups =>
  Object.fromEntries(
    Object.entries(PageType).map<[PageType, SearchResult[]]>(([key]) => [
      key as PageType,
      [],
    ]),
  ) as SearchResultGroups;

const getTotalCount = (results: SearchResultGroups) =>
  Object.values(getSearchSections(results)).reduce<number>(
    (sum, section) => sum + section.length,
    0,
  );
