import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { GatsbyLinkProps, Link } from 'gatsby';

import {
  ConversionButtonProps,
  PageHeadline,
  PageHeadlineProps,
} from '@mewa/ui';

import { extractTextMarking } from '../../libs/translate';

type LinkProps = React.ComponentClass<GatsbyLinkProps<unknown>>;

export type PageHeadlineCTAProps = {
  label: string;
  link: string;
  display?: ConversionButtonProps<LinkProps>['display'];
  onClick?: () => void;
};

type Props = Omit<
  PageHeadlineProps<LinkProps>,
  'cta' | 'title' | 'subtitle'
> & {
  cta?: PageHeadlineCTAProps;
  title: string;
  subtitle?: string | null;
};

export const PageHeadlineWrapper = ({ cta, ...props }: Props) => {
  const markedHeadline = extractTextMarking(props.title);
  let markedSubline;

  if (props.subtitle) {
    markedSubline = extractTextMarking(props.subtitle);
  }

  const ctaProps: PageHeadlineProps<LinkProps>['cta'] | undefined = cta && {
    ...cta,
    onClick: () => {
      if (cta.onClick) {
        cta.onClick();
      }
      DataLayer.push({ event: 'cta_contact_subheadline' });
    },
    link: {
      component: Link,
      props: { to: cta?.link },
    },
  };

  return (
    <PageHeadline
      {...props}
      cta={ctaProps}
      title={
        <>
          {markedHeadline.beforeMarking}
          <strong>{markedHeadline.marking}</strong>
          {markedHeadline.afterMarking}
        </>
      }
      subtitle={
        markedSubline && (
          <>
            {markedSubline.beforeMarking}
            <strong>{markedSubline.marking}</strong>
            {markedSubline.afterMarking}
          </>
        )
      }
    />
  );
};
