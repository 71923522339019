import React, { useRef, useState } from 'react';

import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Grid2 as Grid,
  paperClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { useSectionsHeightAnimation } from '../tabbed-slots/use-sections-height-animation';
import { useTabGlider } from './use-tab-glider';

export const Tabs = ({
  sections,
}: {
  sections: {
    title: string;
    content: React.ReactElement | null;
  }[];
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const tabTitles = useRef<HTMLDivElement>(null);
  const tabsSections = useRef<HTMLDivElement>(null);

  const activeTab = activeIndex ?? 0;
  useSectionsHeightAnimation(400, activeTab, tabsSections);
  useTabGlider(tabTitles, activeTab);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const toggleAccordion = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return isMobile ? (
    <MobileContainer>
      <Grid size={12}>
        {sections.map((section, idx) => (
          <StyledAccordion
            key={idx}
            expanded={idx === activeIndex}
            onChange={() => toggleAccordion(idx)}
          >
            <AccordionSummary>{section.title}</AccordionSummary>
            <StyledAccordionDetails>{section.content}</StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </Grid>
    </MobileContainer>
  ) : (
    <DesktopContainer>
      <ResponsiveGrid>
        <Grid size={12}>
          <TabTitles ref={tabTitles} role="tablist">
            {sections.map((section, idx) => (
              <TabTitle
                key={idx}
                onClick={() => setActiveIndex(idx)}
                id={`tab-${idx}`}
                role="tab"
                aria-controls={`tabPanel-${idx}`}
                aria-selected={idx === activeTab}
              >
                {section.title}
              </TabTitle>
            ))}
          </TabTitles>
        </Grid>
      </ResponsiveGrid>
      <ResponsiveGrid>
        <SectionWrapper size={12} ref={tabsSections}>
          {sections.map((section, idx) => (
            <section
              key={idx}
              id={`tabPanel-${idx}`}
              role="tabpanel"
              aria-labelledby={`tab-${idx}`}
            >
              {section.content}
            </section>
          ))}
        </SectionWrapper>
      </ResponsiveGrid>
    </DesktopContainer>
  );
};

const DesktopContainer = styled('div')(({ theme }) => ({
  marginTop: '60px',
  marginBottom: '70px',

  [theme.breakpoints.up('md')]: {
    marginTop: '70px',
    marginBottom: '80px',
  },

  [theme.breakpoints.up('lg')]: {
    marginTop: '80px',
    marginBottom: '100px',
  },
}));

const MobileContainer = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '60px',
  marginBottom: '70px',
}));

const TabTitles = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '40px',
  justifyContent: 'center',
  alignItems: 'flex-end',
  position: 'relative',
  minHeight: 'calc(calc(var(--font-size-h4) * var(--line-height-h4)) + 15px)',

  '&:after': {
    content: "''",
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '5px',
    scale: 'var(--_width, 0) 1',
    translate: 'var(--_left, 0) 0',
    transformOrigin: 'left',
    transition: 'scale 200ms, translate var(--transition-duration)',
    backgroundColor: 'var(--color-light-red)',
    display: 'var(--_show, none)',
  },
}));

const TabTitle = styled('button')(({ theme }) => ({
  ...theme.typography.h6,
  backgroundColor: 'transparent',
  border: 'none',
  textWrap: 'nowrap',
  padding: '0 0 15px',

  '&.active': {
    ...theme.typography.h4,
    color: 'var(--color-light-red)',
  },

  '&:hover': {
    color: 'var(--color-light-red)',
    cursor: 'pointer',
  },
}));

const SectionWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingTop: '35px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '50px',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '60px',
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${paperClasses.root}.${accordionClasses.root}:first-of-type > .${accordionSummaryClasses.root}`]:
    {
      borderTop: 'none',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  [`&.${accordionDetailsClasses.root}`]: {
    paddingBottom: '34px',
    paddingTop: 0,
  },
});
