import React from 'react';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { FadeImages } from './fade-images';
import { ServiceSegment } from './service-segment';
import { TileImages } from './tile-images';
import { ServiceSegmentsTheme, SlideData, Tags } from './types';

type Props = {
  slidesLeft: SlideData[];
  slidesRight: SlideData[];
  tagsLeft: Tags[];
  tagsRight: Tags[];
  headline: string;
  categoryLeft: string;
  categoryRight: string;
  theme?: ServiceSegmentsTheme;
  fadeDelay?: number;
};

export const ServiceSegmentsOverview = ({
  slidesLeft,
  slidesRight,
  tagsLeft,
  tagsRight,
  headline,
  categoryLeft,
  categoryRight,
  theme = 'dark',
  fadeDelay = 3000,
}: Props) => {
  const getBackgroundColor = () => {
    switch (theme) {
      case 'dark':
        return '#515151';
      case 'white':
        return 'var(--color-white)';
      case 'light':
      default:
        return 'var(--color-light-grey)';
    }
  };

  return (
    <Wrapper
      sx={{
        backgroundColor: getBackgroundColor(),
      }}
      gridProps={{ justifyContent: 'center' }}
      colorTheme={theme}
    >
      <Grid size={12}>
        <Headline
          sx={{
            color: theme === 'dark' ? 'var(--color-white)' : '#000',
          }}
        >
          {headline}
        </Headline>
      </Grid>

      {slidesLeft.length > 0 && (
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <ServiceSegment
            category={categoryLeft}
            tags={tagsLeft}
            colorTheme={theme}
          >
            {slidesLeft.length <= 4 ? (
              <FadeImages slides={slidesLeft} fadeDelay={fadeDelay} />
            ) : (
              <TileImages slides={slidesLeft} />
            )}
          </ServiceSegment>
        </Grid>
      )}

      {slidesRight.length > 0 && (
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <NonClothingServiceSegment
            colorTheme={theme}
            tags={tagsRight}
            category={categoryRight}
          >
            <FadeImages slides={slidesRight} />
          </NonClothingServiceSegment>
        </Grid>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ResponsiveGrid)<{
  colorTheme: ServiceSegmentsTheme;
}>(({ colorTheme, theme }) => ({
  paddingBottom: colorTheme !== 'white' ? '90px' : undefined,
  paddingTop: colorTheme !== 'white' ? '50px' : undefined,

  ...(colorTheme !== 'white'
    ? {
        paddingTop: '50px',

        [theme.breakpoints.up('sm')]: {
          paddingTop: '60px',
        },

        [theme.breakpoints.up('md')]: {
          paddingTop: '70px',
        },

        [theme.breakpoints.up('lg')]: {
          paddingTop: '80px',
        },
      }
    : {}),
}));

const NonClothingServiceSegment = styled(ServiceSegment)<{
  colorTheme: ServiceSegmentsTheme;
}>(({ colorTheme }) => ({
  marginBottom: colorTheme === 'white' ? '-40px' : undefined,
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  textAlign: 'center',

  margin: '0 0 40px',

  [theme.breakpoints.up('md')]: {
    marginBottom: '50px',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: '60px',
  },
}));
