export enum Language {
  DE = 'de',
  FR = 'fr',
  HU = 'hu',
  IT = 'it',
  NL = 'nl',
  CS = 'cs',
  PL = 'pl',
  ES = 'es',
  CA = 'ca',
  SK = 'sk',
  RO = 'ro',
  PT = 'pt',
  EN = 'en',
}

export enum Market {
  DE = 'DE',
  FR = 'FR',
  HU = 'HU',
  CH = 'CH',
  IT = 'IT',
  AT = 'AT',
  BE = 'BE',
  CZ = 'CZ',
  PL = 'PL',
  NL = 'NL',
  ES = 'ES',
  SK = 'SK',
  RO = 'RO',
  PT = 'PT',
  GB = 'GB',
}

export const allMarkets = Object.values(Market);
export const allLanguages = Object.values(Language);

export const marketFromString = (s: string): Market => {
  const idx = allMarkets.indexOf(s as Market);
  if (idx >= 0) {
    return allMarkets[idx];
  }

  throw new Error(`Unknown market: ${s}`);
};

export const languageFromString = (s: string): Language => {
  const idx = allLanguages.indexOf(s as Language);
  if (idx >= 0) {
    return allLanguages[idx];
  }

  throw new Error(`Unknown language: ${s}`);
};

export type MarketLanguage = {
  code: Language;
  localizedMarketName: string;
  localizedLanguageLabel?: string; // only for multi-language markets, for example in the footer
  cmsFallback?: Market; // first language of the fallback market is the fallback language for cms content
  customerPortal: string; // Market BE must use localized links, so we put all links here
  careerPortal: string;
  onlineShop?: string;

  // Generally the domain is set on the market. Market ES is an exception,
  // here the domain is set per language.
  domain?: string;
};

export type MarketData = {
  domain?: string;
  languages: MarketLanguage[];
  serviceSegments: ServiceSegmentType[];
  countryCallingCode: string;
  maxEmployeeCountForSmallCompany: number;
  hasClothingSegments: boolean;
  hideSupplierPortalLink?: boolean;
  socialMedia: Partial<{
    facebook: string;
    instagram: string;
    kununu: string;
    linkedin: string;
    x: string;
    xing: string;
    youtube: string;
    blog: string;
  }>;
};

export type MarketsConfig = Record<Market, MarketData>;

type Model = {
  name: string;
  h1: string | null;
  infoText: string | null;
  keyFacts: string[];
};

export type ObjectMap<T> = Record<string, T>;

export type UUID = string;

export type ProductComponent = {
  title: string;
  titleDE: string;
  items: string[];
};

export type NomenclatureData = {
  name: string;
  displayName: string;
  weightInG: string;
  assetId: string | null;
  model: Model;
  material: string;
  colors: number[][];
  colorName: string | null;
  components: ProductComponent[];
  emotions: string[];
  sizes: string[];
  collection: UUID | null;
  colorVariants: UUID[];
  appropriateMarkets: Market[];
  metaTitle: string | null;
  metaDescription: string | null;
  alternatives: AlternativeLink[];
  serviceSegmentUuid: string;
  serviceSegmentKey: ServiceSegmentType;
};

/**
 * The `Nomenclature` represents the Data for a single nomenclature from the
 * PIM cache. This is an intermediate format for the cache and will be
 * transformed to `Product` and `ProductVariant` in the gatsby-pim-source
 * plugin.
 */
export type Nomenclature = NomenclatureData & {
  uuid: string;
};

export type AlternativeLink = {
  locale: string;
  href: string;
};

export type ServiceSegmentCategory = 'MBS' | 'MTS' | 'MWS' | 'MTR' | 'MFS';

export type ServiceSegmentBase = {
  name: string;
  metaTitle: string;
  metaDescription: string;
  claim: string;
  category: ServiceSegmentCategory;

  // assets
  assets: {
    default: string | null;
    hover: string | null;

    // only relevant for non-clothing categories
    emotion: string | null;
    keyVisualDesktop: string | null;
    keyVisualMobile: string | null;
  };

  // only relevant for none clothing service segments
  property: string | null;
  infoText1: string | null;
  infoText2: string | null;
  galleryTitle: string | null;
  galleryIntroText: string | null;

  seoFooter: string | null;
  alternatives: AlternativeLink[];
  key: ServiceSegmentType;
  appropriateMarkets: Market[];
};

export type ServiceSegment = ServiceSegmentBase & {
  nomenclatureGroups: {
    uuid: string;
    name: string;
    nomenclatures: UUID[];
  }[];
};

export enum ServiceSegmentType {
  workwear = 'workwear',
  protectiveClothing = 'protectiveClothing',
  hygieneClothing = 'hygieneClothing',
  healthClothing = 'healthClothing',
  businessClothing = 'businessClothing',
  serviceClothing = 'serviceClothing',
  cleaningCloths = 'cleaningCloths',
  washroomHygiene = 'washroomHygiene',
  carCovers = 'carCovers',
  oilCollectionMats = 'oilCollectionMats',
  partsCleaner = 'partsCleaner',
  dirtTrappingMats = 'dirtTrappingMats',
  wipingMops = 'wipingMops',
}

export const allServiceSegments = Object.values(ServiceSegmentType);

export enum ServiceSegmentSubType {
  chemicalProtection = 'chemicalProtection',
  productProtection = 'productProtection',
  foundryProtection = 'foundryProtection',
  glassProtection = 'glassProtection',
  heatAndFlameProtection = 'heatAndFlameProtection',
  hiVisProtection = 'hiVisProtection',
  weatherProtection = 'weatherProtection',
  cementProtection = 'cementProtection',
}

export type Product = Pick<
  NomenclatureData,
  | 'displayName'
  | 'collection'
  | 'metaTitle'
  | 'metaDescription'
  | 'alternatives'
  | 'serviceSegmentKey'
  | 'serviceSegmentUuid'
> & {
  uuid: UUID; // this will be the UUID of the first variant
  h1: NomenclatureData['model']['h1'];
  infoText: NomenclatureData['model']['infoText'];
  keyFacts: NomenclatureData['model']['keyFacts'];
  appropriateMarkets: Market[];
  variants: ProductVariant[];
  modelName: NomenclatureData['model']['name'];
};

export type ProductVariant = Pick<
  NomenclatureData,
  | 'name'
  | 'weightInG'
  | 'assetId'
  | 'material'
  | 'colors'
  | 'colorName'
  | 'components'
  | 'emotions'
  | 'sizes'
> & {
  uuid: UUID;
};

export type BrochureDownloadItem = {
  label: string;
  url: string;
  sizeInKiloBytes: number;
  extension: string;
};

export type ServiceSegmentMeta = {
  uuid: string;
  name: string;
  hasServiceSegmentGroups?: boolean; // shows if the service segment contains a 'service segment groups' layer
  children?: Record<UUID, ServiceSegmentSubType>;
};

export type Collection = { uuid: string } & CollectionData;

export type CollectionData = {
  name: string;
  claim: string | null;
  property: string | null;
  infoText1: string | null;
  assets: {
    keyVisualDesktop: string | null;
    keyVisualMobile: string | null;
    emotion: string | null;
  };
  nomenclatures: UUID[];
  alternatives: AlternativeLink[];
};

export type Industry = { uuid: string } & IndustryData;

export type IndustryData = {
  name: string;
  translatedSlugs: {
    locale: string;
    slug: string;
  }[];
  h1: string | null;
  h2: string | null;
  infoText: string | null;
  assets: {
    default: string | null;
    keyVisualClothingDesktop: string | null;
    keyVisualClothingMobile: string | null;
    keyVisualNonClothingDesktop: string | null;
    keyVisualNonClothingMobile: string | null;
    ctaTeaserClothingDesktop: string | null;
    ctaTeaserClothingMobile: string | null;
    ctaTeaserNonClothingDesktop: string | null;
    ctaTeaserNonClothingMobile: string | null;
  };
  serviceSegments: {
    uuid: string;
    nomenclatureGroups: {
      uuid: string;
      name: string;
      nomenclatures: UUID[];
    }[];
    alternatives: AlternativeLink[];
    appropriateMarkets: Market[];
    category: ServiceSegmentCategory;
    key: ServiceSegmentType;
  }[];
};

export enum ServiceTeaserType {
  logoEmbroideryService = 'logo-stick-service',
  cleaningClothsServiceTest = 'putztuch-service-test',
}

export type ServiceTeaserData<T extends ServiceTeaserType> = {
  title: string;
  description: string;
  serviceType: T;
};

export enum MewaAdvantagesIcon {
  MbsWideRange = 'mbs-wide-range',
  MbsIndividualEquipment = 'mbs-individual-equipment',
  MbsNoRiskOfConfusion = 'mbs-no-risk-of-confusion',
  MbsInYourBrandDesign = 'mbs-in-your-brand-design',
  MbsFlawlessHygiene = 'mbs-flawless-hygiene',
  MbsCarefreePackage = 'mbs-carefree-package',
  MbsPureRelief = 'mbs-pure-relief',
  MbsSecureSupplyInYourArea = 'mbs-secure-supply-in-your-area',
  MbsSustainabilityInclusive = 'mbs-sustainability-inclusive',
  MbsComfortable = 'mbs-comfortable',

  MtsGreatVariety = 'mts-great-variety',
  MtsQualityGuaranteed = 'mts-quality-guaranteed',
  MtsPureRelief = 'mts-pure-relief',
  MtsSecureSupplyInYourArea = 'mts-secure-supply-in-your-area',
  MtsSustainabilityInclusive = 'mts-sustainability-inclusive',
  MtsCarefreePackage = 'mts-carefree-package',
  MtsComfortable = 'mts-comfortable',

  CostSaving = 'cost-saving',
  Sustainable = 'sustainable',
  Certificate = 'certificate',
}

export enum MewaStepsIcon {
  CalendarMbs = 'calendar-mbs',
  CalendarMts = 'calendar-mts',
  TruckMbs = 'truck-mbs',
  TruckMts = 'truck-mts',
  CleanAndMaintain = 'clean-and-maintain',
}

export enum MewaCircleIcon {
  CategoryMbsMtsCombined = 'category-mbs-mts-combined', // default
  CategoryMbs = 'category-mbs', // Berufsbekleidung
  CategoryMts = 'category-mts', // Tuchservice
  CategoryMbk = 'category-mbk', // Businesskleidung
  CategoryMfs = 'category-mfs', // Fußmatten
  CategoryMtr = 'category-mtr', // Teilereiniger
  CategoryMultitex = 'category-multitex', // Ölauffangmatten
  CategoryMws = 'category-mws', // Waschraum
  CategoryToptex = 'category-toptex', // KFZ-Schonbezüge
  CategoryWipingmops = 'category-wipingmops', // Wischmopps

  // General icons
  Truck = 'truck',
  Magnifier = 'magnifier',
  Droplet = 'droplet',

  // Special icons for MTR
  MtrCleaning = 'mtr-cleaning',
  MtrChangeLiquid = 'mtr-change-liquid',
  MtrExchange = 'mtr-exchange',
  MtrImmediateHelp = 'mtr-immediate-help',

  // Special icons for clean room
  HeadProtection = 'head-protection',
  PreparationDin14644 = 'preparation-din-14644',
}

export type MewaCircleType =
  | 'default'
  | 'MBS'
  | 'MTS'
  | 'MBK'
  | 'MFS'
  | 'MTR'
  | 'Multitex'
  | 'MWS'
  | 'Toptex'
  | 'Wipingmops'
  | 'CleanRoom';

export enum PageType {
  productDetail = 'productDetail',
  serviceSegment = 'serviceSegment',
  pressRelease = 'pressRelease',
  pressOverview = 'pressOverview',
  blogArticle = 'blogArticle',
  blogOverview = 'blogOverview',
  blogAuthor = 'blogAuthor',
  blogTopic = 'blogTopic',
  collection = 'collection',
  industry = 'industry',
  company = 'company',
  fullService = 'fullService',
  mewaPrinciple = 'mewaPrinciple',
  campaign = 'campaign',
  conversion = 'conversion',
  cleaningClothsServiceTest = 'cleaningClothsServiceTest',
  home = 'home',
  legal = 'legal',
  meta = 'meta',
  search = 'search',
  supplyChainAct = 'supplyChainAct',
  category = 'category',
}

export enum LegalPageType {
  imprint = 'imprint',
  privacyPolicy = 'privacyPolicy',
  privacyPolicySurvey = 'privacyPolicySurvey',
  privacyPolicySocialMedia = 'privacyPolicySocialMedia',
  supplyChainAct = 'supplyChainAct',
}

export type TextWithMarking = {
  beforeMarking: string;
  marking: string;
  afterMarking: string;
};
