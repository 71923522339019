import React from 'react';

import { ServiceSegmentsTags } from './service-segments-tags';
import { ServiceSegmentsTheme, Tags } from './types';

export const ServiceSegment = ({
  children,
  tags,
  category,
  colorTheme,
  className = '',
}: {
  children: React.ReactElement;
  tags: Tags[];
  category: string;
  colorTheme: ServiceSegmentsTheme;
  className?: string;
}) => {
  return (
    <div className={className}>
      {children}
      <ServiceSegmentsTags theme={colorTheme} tags={tags} category={category} />
    </div>
  );
};
