import React, { PropsWithChildren } from 'react';

import { ButtonTypeMap, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import styles from './tags.module.scss';
import { ServiceSegmentsTheme, Tags } from './types';

export type TagProps<T extends React.ElementType> = {
  className?: string;
  onClick?: () => void;
  theme: ServiceSegmentsTheme;

  link: {
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const Tag = <T extends React.ElementType>({
  children,
  className = '',
  link,
  onClick,
  theme = 'dark',
}: PropsWithChildren<TagProps<T>>) => (
  <a
    className={`${styles.tag} ${className} is-theme-${theme === 'dark' ? 'dark' : 'light'}`}
    {...link.props}
    href={link.props['to']}
    onClick={onClick}
  >
    {children}
  </a>
);

export const ServiceSegmentsTags = ({
  category,
  tags,
  theme = 'dark',
}: {
  category: string;
  tags: Tags[];
  theme: ServiceSegmentsTheme;
}) => {
  return (
    <Wrapper>
      <Headline
        sx={{
          color: theme === 'dark' ? '#fff' : '#000',
        }}
      >
        {category}
      </Headline>
      <TagWrapper>
        {tags.map((tag) => (
          <Tag
            key={tag.label}
            theme={theme}
            link={{
              props: {
                to: tag.target,
              },
            }}
          >
            {tag.label}
          </Tag>
        ))}
      </TagWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: '40px',
}));

const TagWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: '10px',
}));

const Headline = styled('h6')(({ theme }) => ({
  ...theme.typography.h6,
  color: 'white',
  margin: '25px 0',
}));
